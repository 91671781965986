//########### section all wrapper side panel ################
.wrapper-all-aside-panel{
  width: 250px;
  height: 100%;
  position: fixed;
  z-index: 9;
  top: 0;
  #{$left}: -250px;
  transition: all 0.5s;
}//########### END section all wrapper side panel ################

.text-orange{
  color: #088190 !important;
}

//########### section wrapper side panel form ################
.wrap-aside-panel{
  width: 250px;
  min-height: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.25);
  position: relative;
  overflow-x: auto;
  transition: all 0.5s;
}//########### END section wrapper side panel form ################

//########### section class imulation open and close ################
.aside-panel__open{
  #{$left}: 0;
  z-index: 9999;
  & .aside-panel-tab{
    width: 60px;
    #{$right}: -60px;
    z-index: 9;
  }
  & .class-hidden{
    display: none;
  }
  & .class-visible{
    display: block !important;
  }
}//########### END section class imulation open and close ################


//########### section panel tab ################
.aside-panel-tab{
  position: absolute;
  top: 40%;
  #{$right}: -90px;
  width: 90px;
  height: 80px;
  background-color: #fff;
  z-index: 1;
	border-radius: corner-values(0 50% 50% 0);
  cursor: pointer;
  box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.25);
}

.aside-panel-tab__icon{
  position: absolute;
  top: 50%;
  #{$right}: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAg1JREFUeNq8VsttwkAQXSwOyc0lQAUxHUAF4ApsV4DdQMBpwFABdgVABZAKQgfQQbjlmhn0VhqhXS/GTkZ6Eng/bz5vxu695KuBUuoKNLaf93njMx4hIJwJG8JM/YMx6Q5RxoQtHCjgzJ+RslXiGac7JXwBKZ51TlrePefoM/wuEP0W2fC7Ir0Qjkhzidq+EUaEIWGFdG+6qH+P1Kt/x7gsJEzxnx1IxP4xIQKhD2c5KxWp+PQMKds3Ig7hgIlYOjkVEZ+gjR05cGlCqomGiMJFrBBxjAwEQhN7OHB1kQZQbIY6KhexHA6vH2tW+RzR66Fzc4D27WykCqQ+olUuYttEIges9fcM+yt4ORbPEhBqsT0yHo8EPjdBxAPtQN+wv0RvRhCVJP4EaSAus0XK0S0wXHhfTk4sZZ9Kk73qGxxKQHqwDQoijNHPKc6MNKGtprofDyKtpnbZoE0mWqGo4wLneS3jNLvUK+2MqEeWdUkcgiwWqbRe3K/RQoWLBlCeqfZKjEaFNstNvflopANcthLD3zQYdEbCR0ehV7Om+yqu2bMAcdJk9nqO9T0unVnEdlOnSSxtSEukLjKsFVjLnn2fuohnd18PS/Rq5hLNs6SVaBEtsDmmVdnmy6HOTkAkWsSvedV1Qsq2RoQFBJRberdTUv0uTEG27OLDzGVXUb9EtbR+g71aUMe2pL8CDADroLvKEtXomQAAAABJRU5ErkJggg==');
  width: 29px;
  height: 28px;
  display: inline-block;
	transform: translateY(-50%);
}

.aside-panel-tab__close-icon{
  position: absolute;
  top: 25px;
  left: 15px;
  color: #088190;
	// transform: translate(-50%, -50%);
}

.aside-panel-tab__txt{
  font-size: 16px;
  font-weight: 800;
  color: #088190;
  white-space: pre-wrap;
  line-height: 1;
  position: absolute;
  top: 50%;
	transform: translateY(-50%);
  #{$right}: 50px;
}//########### END section panel tab ################

.aside-panel__title{
  font-size: 18px;
  color: #000;
  line-height: 1;
}

.aside-panel__title-bold{
  font-size: 24px;
  font-weight: 800;
	line-height: 1;
	&:hover{
		text-decoration: none;
	}
}

//########### class color background box ################
.aside-panel_bg-gray{
  background-color: #f4f4f4;
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  position: relative;
  &:after,
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
  &:before{
    top: auto;
    bottom: 0;
  }
}//########### END class color background box ################

.aside-panel_padding-vertic{
  padding-top: 20px;
  padding-bottom: 20px;
}

.aside-panel_padding-horiz{
  padding-#{$left}: 20px;
  padding-#{$right}: 20px;
}

.aside-panel-tel__image{
  position: relative;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAg5JREFUeNrEV91twjAQDhEP7VMzQjsBdALCBIUJMBMgFiikC4RMEDoB3gB3gqYTNGzAWx9bu/qMThYJdhzISZ9CQnz3+f586d0lm8BRHiViiYHEkDz/kCgkhMTRVtnP6yLoOxpPJSYV/8e4KgJcYmlLJrQksJb4BgG107nEk0SP4JkYZnif2Si38UQOZSWMi4r3CmCD91OsVbL18YQmUGCnwtJzyugY6/JLHqkjMSEExi7JRjwzxbocOeVMIoWCaQMCWnQIAxIaaxIMzDMo8hGOMMZV3qgi8WKTUA6Skc1Zk4gR07IlEhzXkQuJCCTaFOESDt2KD0H7Yk0iCm4soWFctdo97lcSv3WlZSFK5yf06LNF/d5Vte0U7lJJ9EWqhCG7m+QIQ3hNnROAUxK0tWZGex56hCiq0blDI+QhjLOgG8l1ToxabkwuZ0t0/5bFISmbQwckTtWhb2Y3JqEntDJE0hzrjtoriUpaIWfMfxKlMS/QSnho0aCpU6A6TiWqB5C92UhAruk5ItD0zuk8zSl9Y8H8TFi4x1CjdztEzindiflZ0D8zG7YtHFjA+LrpyO8rcd14cCsSK1zfuyKRwxPba3iC4Vim2JNyjFAV+rNh6fMFFlRMXzl6jHbxAF1wh+fMqJBj2yT0bhOjoujwUqAbX6y4piRKMgjNDGIb1+8VHxJzkIiNPpO4Nrc/AQYAXJWM+m5mg4sAAAAASUVORK5CYII=');
  width: 33px;
  height: 33px;
  display: inline-block;
  margin-top: 7px;
  margin-#{$left}: 15px;
}

.aside-panel-mail__image{
  position: relative;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAcCAYAAACdz7SqAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAg1JREFUeNq8VsttwkAQXSwOyc0lQAUxHUAF4ApsV4DdQMBpwFABdgVABZAKQgfQQbjlmhn0VhqhXS/GTkZ6Eng/bz5vxu695KuBUuoKNLaf93njMx4hIJwJG8JM/YMx6Q5RxoQtHCjgzJ+RslXiGac7JXwBKZ51TlrePefoM/wuEP0W2fC7Ir0Qjkhzidq+EUaEIWGFdG+6qH+P1Kt/x7gsJEzxnx1IxP4xIQKhD2c5KxWp+PQMKds3Ig7hgIlYOjkVEZ+gjR05cGlCqomGiMJFrBBxjAwEQhN7OHB1kQZQbIY6KhexHA6vH2tW+RzR66Fzc4D27WykCqQ+olUuYttEIges9fcM+yt4ORbPEhBqsT0yHo8EPjdBxAPtQN+wv0RvRhCVJP4EaSAus0XK0S0wXHhfTk4sZZ9Kk73qGxxKQHqwDQoijNHPKc6MNKGtprofDyKtpnbZoE0mWqGo4wLneS3jNLvUK+2MqEeWdUkcgiwWqbRe3K/RQoWLBlCeqfZKjEaFNstNvflopANcthLD3zQYdEbCR0ehV7Om+yqu2bMAcdJk9nqO9T0unVnEdlOnSSxtSEukLjKsFVjLnn2fuohnd18PS/Rq5hLNs6SVaBEtsDmmVdnmy6HOTkAkWsSvedV1Qsq2RoQFBJRberdTUv0uTEG27OLDzGVXUb9EtbR+g71aUMe2pL8CDADroLvKEtXomQAAAABJRU5ErkJggg==');
  width: 29px;
  height: 28px;
  display: inline-block;
  margin-top: 7px;
  margin-#{$left}: 15px;
}

.aside-panel-mail__link{
  font-size: 14px;
	line-height: 1;
	&:hover{
		text-decoration: none;
	}
}

//########### section form ################
.aside-panel-form{
  margin-top: 10px;
  & > .form-group{
    margin-bottom: 10px;
  }
}

.aside-panel-form__input{//inputs size
  height: 39px;
	width: 100%;
	&::placeholder{
		opacity: 1;
		color: #415667;
		transition: all 0.4s ease;
	}
  &:focus{
		outline: none;
    &::placeholder{
			opacity: 0;
		}
  }
}

.aside-panel-form__input_style{//inputs style
  background-color: #f6f6f6;
  color: #415667;
  font-size: 18px;
  padding: 0 15px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.1);
}

.aside-panel-form__textarea{//textarea size
  height: 109px;
  padding-top: 7px;
  resize: none;
}

.aside-panel-form__input-btn_style{//button style
  background-color: #ab2f24;
  border: 0;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  border-radius: 3px;
  &:active{
    @include btn-effect-active;
  }
}//########### END section form ################

//########### section social ################
.aside-panel-social{
	display: block;
}

.aside-panel-social__box{
  width: 83px;
  border-#{$left}: 1px solid #d6d6d6;
  position: relative;
	padding: 16px 0;
	&:hover{
		text-decoration: none;
	}
  &:after{
    content: "";
    position: absolute;
    top: 0;
    #{$left}: -2px;
    width: 1px;
    height: 100%;
    background-color: #fff;
  }
}

.aside-panel__social-title{
  color: #415667;
  font-size: 16px;
  line-height: 1;
}

.aside-panel-social__icon{
  margin-bottom: 8px;
  display: inline-block;
  & > .fa-facebook{
    color: $clr-facebook;
  }
  & > .fa-youtube{
    color: $clr-youtube;
  }
  & > .fa-linkedin{
    color: $clr-linkedin;
  }
}//########### END section social ################



@keyframes expand {
  from {
    top: -50px;
  }
}

.alert-custom{
  display: block;
  height: auto;
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1000;
  margin: 0 auto;
  max-width: 720px;
  animation: expand .3s ease-in-out;
}