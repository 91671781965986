.ftr-form{
  @include respond-to('small'){
    text-align: center;
    margin-bottom: 20px;
  }
  .has-feedback .form-control{
    padding-left: 12px;
    padding-right: 12px;
	}
	& .form-group{
		margin-bottom: 10px;
	}
}

.ftr-form-title{
  margin-bottom: 10px;
  & > p{
    font-size: 18px;
    color: #fff;
    line-height: 0.8;
    margin: 0;
    &  > span{
      font-size: 24px;
      color: #ec6a60;
      font-weight: 800;
    }
  }
}

.newslatter-input{
  width: 210px;
  height: 38px;
  background-color: #fff;
  position: relative;
  color: #6b7379;
  font-size: 18px;
  padding: 5px 15px;
  border-radius: 0;
  box-shadow: none;
  @include respond-to('small'){
    margin-left: auto;
    margin-right: auto;
  }
  &:focus{
    outline: none;
    &::placeholder{
      opacity: 0;
    }
  }
  &::placeholder{
    color: #6b7379;
    font-size: 18px;
    transition: opacity 0.4s ease;
	}
	&_textarea{
		resize: none;
		height: 60px;
	}
}

.newslatter-btn{
  display: block;
  width: 210px;
  height: 38px;
  font-size: 18px;
  color: #fff;
  border: 0;
  background-color: #088190;
  position: relative;
  @include respond-to('small'){
    margin-left: auto;
    margin-right: auto;
  }
  &:focus{
    outline: none;
  }
  &:active{
    @include btn-effect-active;
  }
  & > svg{
    font-size: 18px;
    margin-#{$right}: 5px;
    transition: all 0.4s;
  }
  &:hover{
    & > svg{
      transform: translateX(-4px);
    }
  }
}

.ftr-check-uncheck{
  position: relative;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin-#{$left}: 5px;
  label {
    width: 23px;
    height: 23px;
    cursor: pointer;
    position: absolute;
    background-color: #eeeeee;
    border: 1px solid #bebebe;
    #{$left}: 0;
    top: 0;
    &:after {
      content: '';
      width: 12px;
      height: 6px;
      position: absolute;
      top: 6px;
      #{$left}: 5px;
      border: 2px solid #000;
      border-top: none;
      border-#{$right}: none;
      background: 0 0;
      opacity: 0;
      transform: rotate(-45deg);
    }
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}

.ftr-checkbox-para{
  display: inline-block;
  & > p{
    font-size: 16px;
    font-weight: 300;
    color: #000;
    margin: 0;
    @include respond-to('larger'){
      font-size: 13px;
    }
  }
}