.wrap-content{
  padding: 20px 0;
}

.title{
	font-size: 36px;
	font-weight: 800;
  line-height: 1;
  color: #088190;
	margin: 15px 0 30px;
	@include respond-to('small'){
		font-size: 32px;
	}
	@include respond-to('extra-small'){
		font-size: 26px;
	}
}

.hr-content{
  margin-top: 35px;
	margin-bottom: 35px;
	border-color: #a62318;
	@include respond-to('medium'){
		margin: 20px 0;
	}
}

.content-text{
  h2,h3{
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    color: #089ea8;
		margin: 20px 0 20px;
		@include respond-to('large'){
      font-size: 26px;
    }
    @include respond-to('extra-small'){
      font-size: 20px;
    }
	}
	& ul{
		list-style-position: inside;
		& > li{
			font-size: 22px;
    	color: #425f6d;
		}
	}
  p{
    font-size: 22px;
    color: #425f6d;
		line-height: 1.2;
		@include respond-to('extra-small'){
			font-size: 16px;
			text-align: justify;
		}
  }
  iframe{
    @include respond-to('small'){
      text-align: center !important;
      margin: 15px auto !important;
      width: 100% !important;
      height: auto !important;
      float: none !important;
      display: block !important;
    }
  }
  img{
    @include respond-to('small'){
      float: none !important;
      text-align: center !important;
      width: auto !important;
      height: auto !important;
      margin: 15px auto !important;
    }
  }
}
