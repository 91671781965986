.main-header{
  width:100%;
  z-index:99;
  position: fixed;
  top: 0;
	transition: all 0.4s ease;
	@include respond-to('medium'){
		height: 70px;
		background-color: #fff;
		box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		background-image: url('../images/header-shadow.png');
		width: 100%;
		height: 185px;
		pointer-events: none;
		z-index: -1;
		transition: all 0.4s;
		@include respond-to('medium'){
			display: none;
		}
	}
}

.header-desktop{
	max-width: 1300px;
	width: 100%;
	margin: 0 auto;
  height: 100px;
  @include respond-to('medium'){
    display:none;
  }
}

.logo{
	float: $right;
	margin: side-values(5px -55px 0 0);
}

.main-navi{
	float: $left;
	position: relative;
		&:after{
			content: "";
			position: absolute;
			top: 45px;
			#{$left}: 0;
			background-image: $header-line-icon;
			width: 1086px;
			height: 1px;
		}
  & > ul{
    display: inline-block;
    list-style: none;
    margin: side-values(55px 20px 0 0);
		padding: 0;
		
    & > li{
      float: right;
      margin-#{$left}: 25px;
			position: relative;
			@include respond-to('large'){
				margin-#{$left}: 10px;
			}
      & > a{
        font-size: 24px;
				color: #0b1c24;
				font-weight: 300;
				display: block;
				line-height: 1;
				@include respond-to('large'){
					font-size: 22px;
				}
        &:hover{
          text-decoration: none;
        }
        &:focus,
        &:active{
          text-decoration: none;
        }
      }
    }
  }
}

.number-phone{
	display: inline-block;
	font-size: 20px;
	color: #a62318;
	font-weight: 900;
	font-family: 'Roboto', sans-serif;
	&__phone-icon{
		background-image: $header-phone-icon;
		width: 21px;
		height: 22px;
		display: inline-block;
		vertical-align: text-top;
		margin-#{$left}: 6px;
	}
}

.header-mobile{
  display:none;
  height: 70px;
  @include respond-to('medium'){
    display:block;
  }
  &__logo{
    margin-#{$left}: 15px;
    margin-top: 10px;
    float: $left;
    & > img{
      width: 200px;
    }
  }
  &__tel{
    display: inline-block;
    margin-top: 17px;
    svg{
      color: #088190;
      font-size: 32px;
    }
  }
}

.main-header.scrolling{
	height: 90px;
	position: fixed;
	background-color: #fff;
	box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
	@include respond-to('medium'){
		height: 70px !important;
	}
	&:after{
		height: 0;
	}
}
