.wrap-article-box{
  display: block;
  &:hover{
    .article-box-image__item{
      transform: scale(1.07);
    }
  }
}

.article-box-image{
  width: 144px;
  height: 144px;
	overflow: hidden;
	margin: 0 auto;
	border: 1px solid #7cbcc4;
	border-radius: 50%;
	& > img{
		text-align: center;
		max-width: 100%;
		max-height: 100%;
		transition: all 0.6s;
	}
}

.article-box-image__item{
  background-position: center center;
  background-size: cover;
  width: 145px;
  height: 145px;
  transition: all 0.6s;
}

.article-box-data__title{
  font-size: 24px;
  color: #a62318;
  line-height: 1;
  margin-bottom: 25px;
  @include respond-to('extra-small'){
    font-size: 22px;
    margin: 10px 0 15px;
  }
}

.article-box-data__content{
  //min-height: 95px;
  //max-height: 105px;
  overflow: hidden;
  text-align: justify;
  & > p{
    font-size: 22px;
    color: #425f6d;
    margin: 0;
    line-height: 1.2;
  }
}

.article-box__btn{
  display: inline-block;
  font-size: 22px;
  color: #088190;
  font-weight: 600;
  margin-top: 15px;
  &:hover,&:active,&:focus{
    color: #088190;
  }
}
