.wrapper-form-boxes{
	padding: 0 60px;
	@include respond-to('medium'){
		padding: 15px;
	}
}

.form-box{
	background-color: #fcfcfc;
	padding: 40px 15px 20px;
	border: 1px solid #e5e5e5;
	@include respond-to('medium'){
		padding: 10px;
		margin-bottom: 10px;
	}
	&__title{
		font-size: 25px;
		font-weight: bold;
		color: #ab2f24;
		margin-bottom: 40px;
	}
	&_min-height{
		min-height: 562px;
		@include respond-to('medium'){
			min-height: auto;
		}
	}
}

.form-box-contacts{
	margin-bottom: 45px;
	padding-#{$right}: 70px;
	@include respond-to('medium'){
		padding-#{$right}: 0px;
		margin-bottom: 20px;
	}
	&__title{
		font-size: 18px;
		font-weight: 600;
		color: #777777;
		display: inline-block;
	}
	&__icon{
		display: inline-block;
		margin-#{$left}: 20px;
		vertical-align: text-bottom;
		&_loc{
			background-image: $loc-icon;
			width: 39px;
			height: 38px;
		}
		&_tel{
			background-image: $tel-icon;
			width: 33px;
			height: 33px;
		}
		&_fax{
			background-image: $fax-icon;
			width: 31px;
			height: 24px;
		}
		&_mail{
			background-image: $mail-icon;
			width: 37px;
			height: 25px;
		}
	}
}

.form-map{
	margin-top: 20px;
	background-color: #fcfcfc;
	padding: 25px;
	border: 1px solid #e5e5e5;
	p{
		margin: 0;
	}
	@include respond-to('medium'){
		& iframe{
			width: 100% !important;
			height: 100% !important;
		}
	}
}