.video-box{
	max-width: 460px;
	width: 100%;
	text-align: center;
	margin-bottom: 15px;
	&__title{
		font-size: 20px;
		color: #415667;
	}
	&__sub-title{
		font-size: 14px;
		color: #939393;
	}
	&__description{
		font-size: 14px;
		font-weight: 300;
		color: #415667;
	}
}

.video-box-image{
	margin-bottom: 20px;
	display: block;
	position: relative;
	& svg.fa-youtube{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $clr-youtube;
	}
}