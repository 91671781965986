.aside-navi{
  & > p{
    font-size: 26px;
    color: #088190;
    margin-bottom: 15px;
  }
  & > ul{
    padding: 0;
    margin: 0;
    list-style: none;
    & > li{
      border-bottom: 1px solid #e0e0e0;
      line-height: 40px;
      position: relative;
      &:first-child{
        border-top: 1px solid #e0e0e0;
      }
      &:hover{
        & > a{
					color: #0c8391;
					text-decoration: none;
        }
        &:after{ width: 100%;}
      }
      & > a{ 
        font-size: 20px;
        color: #415667;
        display: block;
      }
      &.aside-navi_selected{
        & > a{
          color: #0c8391;
        }
        &:after{ width: 100%;}
      }
      &:after{
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 0%;
        height: 2px;
        background-color: #a62318;
        margin: 0 auto;
        transition: all .3s ease-in-out;
      }
    }
  }
}
