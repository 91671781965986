.wrap-hp-boxes{
	& div[class^="col-"]{
		padding: 0;
		&:last-child{
			& .hp-cat-box{
				border-#{$left}: 1px solid #d9d9d9;
				@include respond-to('medium'){
					border-#{$left}: 0;
				}
			}
		}
	}
	@media(min-width: 998px){
		.col-md-2{
			width: 13.666%;
		}
	}
}

//hp cat box
.hp-cat-box{
  display: block;
  max-width: 217px;
  height: 350px;
  border-#{$right}: 1px solid #d9d9d9;
  padding: 30px 10px 10px;
  position: relative;
  transition: all 0.3s;
  background-color: #fff;
  @include respond-to('medium'){
    margin-left: auto;
    margin-right: auto;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
  }
  @include respond-to('extra-small'){
    height: 300px;
  }
  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    height: 5px;
    background-color: #057571;
    transition: all 0.3s;
  }
  &:hover{
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.2);
		z-index: 1;
		text-decoration: none;
    &:after{
      width: 100%;
    }
  }
  &__image{
    width: 100%;
    height: 100px;
    text-align: center;
    overflow: hidden;
    margin-bottom: 20px;
    @include respond-to('extra-small'){
      height: 80px;
    }
    & > img{
      max-width: 99%;
      max-height: 99%;
      // @include respond-to('extra-small'){
      //   max-height: 90%;
      // }
    }
  }
  &__title{
    font-size: 20px;
    color: #a62318;
    font-weight: 300;
    text-align: center;
		margin-bottom: 15px;
    line-height: 1.2;
    height: 72px;
    overflow: hidden;
		@include respond-to('large'){
			font-size: 18px;
		}
    @include respond-to('extra-small'){
      font-size: 16px;
      padding: 0;
    }
  }
  &__description{
    text-align: center;
    font-size: 17px;
    font-weight: 300;
    max-height: 100px;
		overflow: hidden;
		color: #000;
		@include respond-to('large'){
			font-size: 16px;
		}
    @include respond-to('extra-small'){
      max-height: 106px;
    }
  }
}//END hp cat box
