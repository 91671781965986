.wrap-content-left{
  height: 100%;
  padding-top: 38px;
  position: relative;
  padding-bottom: 20px;
  padding-#{$right}: 40px;
  @include respond-to('medium'){
    padding: 20px 15px;
    padding-#{$right}: 15px;
  }
  &:before{
    content: "";
    position: absolute;
    width: 20px;
    top: -9999px;
    bottom: -9999px;
    #{$right}: 0;
    box-shadow: 10px 0 18px 0 rgba(0,0,0,.1);
    @include respond-to('medium'){
      display: none;
    }
  }
}

.wrap-content-right{
  height: 100%;
  padding-top: 45px;
  position: relative;
  padding-bottom: 20px;
  padding-#{$left}: 50px;
  @include respond-to('medium'){
    display: none;
  }
}

.title-aside-form{
	font-size: 26px;
	line-height: 1;
	color: #0c8391;
	margin: 40px 0 10px;
	border-bottom: 1px solid #e0e0e0;
	padding-bottom: 20px;
}

.overflow-hidden{
  overflow: hidden;
}

