.wrapper-banners{
  position: relative;
  width: 100%;
  height: 800px;
  overflow: hidden;
  @include respond-to('huge'){
    height: 650px;
  }
  @include respond-to('large'){
    height: 450px;
  }
  @include respond-to('extra-small'){
    height: 350px;
  }
}

.wrap-banner-scroll{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include respond-to('medium'){
    position: static;
  }
}

.banner-item{
  width: 100%;
  min-height: 800px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include respond-to('huge'){
    min-height: 650px;
  }
  @include respond-to('large'){
    min-height: 450px;
  }
  @include respond-to('extra-small'){
    min-height: 350px;
  }
}

.wrapper-banner-content{
	max-width: 1300px;
	width: 100%;
	margin: 0 auto;
	position: absolute;
	height: 100%;
	left: 0;
	right: 0;
}

.banner-content{
	position: absolute;
	max-width: 480px;
	width: 100%;
	bottom: 25%;
	left: 0;
  text-shadow: 1px 1px 6px rgba(255, 255, 255, 0.9);
	@include respond-to('extra-small'){
		padding: 0 15px;
	}
	&__title{
		font-size: 75px;
		font-weight: bold;
		color: #a62318;
		line-height: 0.8;
		@include respond-to('large'){
			font-size: 42px;
		}
		@include respond-to('extra-small'){
			font-size: 22px;
		}
	}
	&__sub-title{
		font-size: 41px;
		color: #005d69;
		line-height: 1;
		text-align: $left;
		@include respond-to('large'){
			font-size: 36px;
		}
		@include respond-to('extra-small'){
			font-size: 20px;
			text-align: $right;
		}
	}
}

.arrow-down{
  position: absolute;
  left: 50%;
  bottom: 140px;
  transform: translateX(-50%);
  background: $arrow-scroll-icon no-repeat;
  width: 57px;
  height: 57px;
  cursor: pointer;
  z-index: 2;
  animation-name: arrow-down-animate;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  @include respond-to('large'){
    bottom: 30px;
  }
  @include respond-to('extra-small'){
     bottom: 15px;
  }
}

@keyframes arrow-down-animate {
  16.65% {
    transform: translateY(0px) translateX(-50%);
  }
  33.3% {
    transform: translateY(-2px) translateX(-50%);
  }
  49.95% {
    transform: translateY(2px) translateX(-50%);
  }
  66.6% {
    transform: translateY(-2px) translateX(-50%);
  }
  83.25% {
    transform: translateY(2px) translateX(-50%);
  }
  100% {
    transform: translateY(0px) translateX(-50%);
  }
}
