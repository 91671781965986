.wrap-content-staff{
	position: relative;
	overflow: hidden;
}

.line-full-height{
	position: relative;
	overflow: hidden;
	&:before{
    content: "";
    position: absolute;
    width: 1px;
    top: -9999px;
		bottom: -9999px;
		#{$left}: 0;
    border: 1px solid #088190;
    @include respond-to('medium'){
      display: none;
    }
	}
}

.name-staff{
	font-size: 24px;
	font-weight: bold;
	color: #a62318;
	margin: 0 0 30px;
}

.image-staff{
	max-width: 200px;
	width: 100%;
	max-height: 210px;
	border: 1px solid #85c1c8;
	overflow: hidden;
	margin-bottom: 100px;
	@include respond-to('medium'){
		margin-bottom: 20px;
	}
	& > img{
		text-align: center;
	}
}

.staff-form-title{
	font-size: 20px;
	color: #088190;
	margin-bottom: 15px;
}