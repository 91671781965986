.article-item-box{
	display: block;
	margin-bottom: 30px;
	&_dir-ltr{
		direction: ltr;
	}
	&:hover{
		text-decoration: none;
	}
	&__title{
		font-size: 18px;
		color: #939393;
		@include respond-to('extra-small'){
			font-size: 16px;
		}
	}
	&__sub-title{
		font-size: 18px;
		color: #138795;
		font-weight: 600;
		@include respond-to('extra-small'){
			font-size: 16px;
		}
	}
	&__description{
		font-size: 14px;
		color: #939393;
	}
}