.wrapper-all-content{
  background-color:#fff;
  position: relative;
  width: 100%;
}

.wrap-all-content-not-banner{
	margin-top: 150px; 
	@include respond-to('medium'){
		margin-top: 70px;
	}
}

.text-white{
  color: #fff;
}

.text-dark-blue{
  color: #088190;
}

.text-bright-blue{
  color: #59cfdf;
}

.text-black{
  color: #0b2528;
}

.text-bordo{
  color: #a62318;
}

.text-bright-bordo{
  color: #ec6a60;
}

.h2-no-margin{
  margin-top: 0;
  margin-bottom: 0;
}

@include respond-to('small'){
  .mobile-change-color-txt-dark{
    color: #415667 !important;
  }
}

.text-size50-extra{
  font-size: 50px;
  font-weight: 800;
  @include respond-to('large'){
    font-size: 42px;
  }
  @include respond-to('small'){
    font-size: 32px;
  }
  @include respond-to('extra-small'){
    font-size: 26px;
  }
}

.text-size40-extra{
  font-size: 40px;
  font-weight: 800;
  @include respond-to('large'){
    font-size: 32px;
  }
  @include respond-to('small'){
    font-size: 26px;
  }
}

.text-size30{
  font-size: 30px;
  @include respond-to('large'){
    font-size: 28px;
  }
  @include respond-to('small'){
    font-size: 22px;
  }
}

.text-size20{
  font-size: 20px;
}

.text-height-1{
  line-height: 1;
}

.text-style-link{
  display: inline-block;
  font-size: 18px;
  font-weight: 800;
  padding-top: 8px;
  margin-top: 50px;
  @include respond-to('medium'){
    margin-top: 0;
  }
  @include respond-to('extra-small'){
    font-size: 16px;
  }
  &_dark-blue{
    color: #088190;
    border-top: 2px solid #a62318;
    &:hover,&:active,&:focus{
      color: #088190;
    }
  }
  &_bright-blue{
    color: #59cfdf;
    border-top: 2px solid #ec6a60;
    &:hover,&:active,&:focus{
      color: #59cfdf;
    }
  }
}

.text-style-link{
  @include respond-to('medium'){
    margin-bottom: 20px;
  }
}

.cont-link{
  font-size: 20px;
}

.padding-left-20{
  padding-left: 20px;
  @include respond-to('medium'){
    padding-left: 0;
  }
}

.padding-right-20{
  padding-right: 20px;
  @include respond-to('medium'){
    padding-right: 0;
  }
}

.hp-section-consultant{
  width: 100%;
  min-height: 700px;
  position: relative;
  background-position: center center;
  background-size: cover;
  @media(max-width: 1900px){
    min-height: 600px; 
  }
  @media(max-width: 1800px){
    min-height: 500px; 
  }
  @include respond-to('large'){
    min-height: inherit;
    padding: 20px 0;
  }
  @include respond-to('small'){
    background-image:none !important;
  }
}

.hp-section-consultant-data{
  padding-top: 145px;
  display: block;
  @media(max-width: 1800px){
    padding-top: 70px; 
  }
  @include respond-to('large'){
    padding-top: 0;
  }
  &:hover,&:focus,&:active{
    text-decoration: none;
  }
}

.hp-section-consultant-data__content {
	margin-top: 40px;
}



.hp-articles {
	padding-bottom: 30px;
	background-position: center;
	background-size: cover;
  background-repeat: no-repeat;
 
	&__title {}
	&__items {
		font-size: 0;
		text-align: center;
		margin: 0 -20px;
		@include respond-to('medium') {
			margin: 0;
		}
	}
	&__item {
		display: inline-block;
		vertical-align: top;
		width: 33.33%;
		margin-bottom: 60px;
		padding: 0 15px;
    font-size: initial;
    &:hover,&:focus{
      text-decoration: none;
    }
		@include respond-to('large') {
			padding: 0 5px;
		}
		@include respond-to('medium') {
			margin-bottom: 30px;
			padding: 0;
		}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
}

.hp-article-item {
	padding: 0 5px;
	max-width: 380px;
	width: 100%;
	margin: 0 auto;
	&__img {
		width: 100%;
		height: 270px;
		margin: 0 auto 35px;
		border: 1px solid #fff;
		border-bottom: none;
		text-align: center;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		@include respond-to('medium') {
			height: 230px;
			margin: 0 auto 25px;
		}
	}
	&__title {
		margin-bottom: 25px;
		&.title-hp {
			@include respond-to('medium') {
				font-size: 25px;
			}
		}
	}
	&__desc {
		max-width: 310px;
		width: 100%;
		margin: 0 auto;
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.2;
		@include respond-to('medium') {
			font-size: 17px;
			padding: 0 10px;
		}
	}
	&__btn {
		margin-top: 50px;
		@include respond-to('medium') {
			margin-top: 30px;
		}
	}
}





[dir=rtl] {
  #hp-articles-slider{
    .slick-prev{
      left: auto;
      right: -25px;
      @include respond-to('small') {
        right: -10px;
      }
    }
    .slick-next{
      right: auto;
      left: -25px;
      @include respond-to('small') {
        left: -10px;
      }
    }
  }
  
}



.title-hp {
	font-size: 30px;
	font-weight: 600;
	color: #000;
	text-align: center;
	line-height: 0.9;
	@include respond-to('extra-small') {
		font-size: 25px;
	}
	&_mb {
		margin-bottom: 30px;
		@include respond-to('extra-small') {
			margin-bottom: 20px;
		}
	}
	&_mb-big {
		margin-bottom: 90px;
		@include respond-to('huge') {
			margin-bottom: 80px;
		}
		@include respond-to('medium') {
			margin-bottom: 40px;
		}
		@include respond-to('small') {
			margin-bottom: 30px;
		}
		@include respond-to('extra-small') {
			margin-bottom: 25px;
		}
	}
	&_white {
		color: #fff;
	}
}

.slick-next:before, .slick-prev:before{
  color: #000 !important;
}

  #hp-articles-slider{
    .slick-prev{
      right: auto;
      left: -25px;
      @include respond-to('small') {
        left: -10px;
      }
    }
    .slick-next{
      left: auto;
      right: -25px;
      @include respond-to('small') {
        right: -10px;
      }
    }
  }

  .link-btn {
    display: inline-block;
    width: 160px;
    height: 60px;
    padding-top: 20px;
    background-color: #088190;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 1;
    text-align: center;
    &:hover{
      text-decoration: none;
      color: #fff;
    }
  }