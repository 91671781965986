footer{
  width:100%;
  position: relative;
  z-index: 1;
	padding: 50px 0 20px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#405565+0,1f2c36+100 */
	background: #405565; /* Old browsers */
	background: -moz-linear-gradient(top, #405565 0%, #1f2c36 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #405565 0%,#1f2c36 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #405565 0%,#1f2c36 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#405565', endColorstr='#1f2c36',GradientType=0 ); /* IE6-9 */
	box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.3),inset 0px -5px 5px 0px rgba(0,0,0,0.3);
  @include respond-to('large'){
    padding: 20px 0;
  }
}

.hr-footer-bottom{
  border: 0;
  border-top: 1px solid #bebebe;
  margin-top: 40px;
  margin-bottom: 20px;
}


.credit-info-txt{
  float: $right;
  @include respond-to('medium'){
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  @include respond-to('small'){
		margin-right: 0;
		float: none;
  }
  & > p{
    font-size: 20px;
    color: #ec6a60;
    margin: 0;
    @include respond-to('extra-small'){
      font-size: 14px;
    }
  }
}

.wrap-social{
  position: relative;
	vertical-align: middle;
	float: $left;
  
  @include respond-to('medium'){
    display: block;
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
	}
	@include respond-to('small'){
		float: none;
	}
	&__youtube{
		background-color: $clr-youtube;
	}
	&__facebook{
		background-color: $clr-facebook;
		
	}
  & > a{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: inline-block;
		position: relative;
		margin-#{$left}: 10px;
    & > svg{
      color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
    }
  }
}
